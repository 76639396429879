import React from 'react'




const AboutPage = () => {
    return (
        <h1>About page</h1>
    )
}

export default AboutPage